var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "login-layout",
    {
      attrs: {
        errors: _vm.errors,
        info2: _vm.info2,
        info2callback: _vm.info2callback
      },
      on: {
        submit: _vm.processStep,
        errors: function($event) {
          _vm.errors = $event
        }
      }
    },
    [
      _c(
        "div",
        { staticClass: "login-form" },
        [
          _c("h2", { staticClass: "title" }, [
            _vm._v("\n      " + _vm._s(_vm.$t(_vm.resolveTitle())) + "\n    ")
          ]),
          _vm._v(" "),
          !_vm.isBack
            ? _c("p", { staticClass: "text" }, [
                _vm._v(
                  "\n      " + _vm._s(_vm.$t(_vm.resolveNote())) + "\n    "
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("username-input", {
            ref: "username",
            staticClass: "username",
            class: {
              invalid: _vm.errors[0] && _vm.errors[0].tKey !== "verify_humanity"
            },
            attrs: {
              "edit-mode": _vm.step === "username",
              label: _vm._f("sentence")(
                _vm.$t(_vm.account === "standard" ? "username" : "email")
              ),
              autofocus: _vm.step === "username",
              name: "username",
              required: ""
            },
            on: {
              "edit-start": _vm.onEditStart,
              input: function($event) {
                _vm.errors.length = 0
                _vm.legacyLoginUrl = ""
                _vm.legacyPlatform = ""
              }
            },
            model: {
              value: _vm.username,
              callback: function($$v) {
                _vm.username = $$v
              },
              expression: "username"
            }
          }),
          _vm._v(" "),
          !_vm.showWebauthnLogin &&
          this.compliesWithAuthMethodRestrictions("password")
            ? _c("password-input", {
                ref: "password",
                staticClass: "password",
                class: {
                  invalid: !!_vm.errors.length,
                  shrinked: _vm.step !== "password"
                },
                attrs: {
                  placeholder: _vm.$t("password"),
                  autofocus: _vm.step === "password",
                  name: "password",
                  required: ""
                },
                on: {
                  input: function($event) {
                    _vm.errors.length = 0
                  }
                },
                model: {
                  value: _vm.password,
                  callback: function($$v) {
                    _vm.password = $$v
                  },
                  expression: "password"
                }
              })
            : _vm._e(),
          _vm._v(" "),
          !_vm.showWebauthnLogin || _vm.loading
            ? _c("recaptcha-policy", { staticClass: "captcha" })
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "template",
        { slot: "lower" },
        [
          !!_vm.legacyLoginUrl
            ? _c("div", { staticClass: "message legacy-platform-notice" }, [
                _c("img", { attrs: { src: _vm.iconInfo, alt: "info icon" } }),
                _vm._v(" "),
                _c("div", [
                  _c("p", [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm.$t(
                            "info.user_is_legacy." +
                              this.legacyPlatform +
                              ".text"
                          )
                        ) +
                        "\n        "
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      staticClass:
                        "btn btn-primary btn-sm right-button legacy-login",
                      attrs: { href: _vm.legacyLoginUrl, rel: "nofollow" }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm.$t(
                              "info.user_is_legacy." +
                                this.legacyPlatform +
                                ".button"
                            )
                          ) +
                          "\n        "
                      )
                    ]
                  )
                ])
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.showMainButton
            ? _c("link-and-button", {
                attrs: {
                  link: _vm.resolveLink(),
                  button: { tKey: _vm.btnText },
                  disabled: _vm.loading || !!_vm.legacyLoginUrl,
                  loading: _vm.loading
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.showWebauthnLogin && !_vm.loading
            ? _c("webauthn", {
                attrs: {
                  "animate-success": _vm.animateWebauthn,
                  "alternative-process-title": _vm.$t(
                    "webauthn.use_standard_login"
                  ),
                  "try-again-title": _vm.$t("webauthn.try_again_passwordless"),
                  "auth-type": _vm.authType
                },
                on: {
                  "authenticate-clicked": _vm.webauthnLogin,
                  "alternative-process": function($event) {
                    return _vm.onStandardLogin()
                  }
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.usecase !== "restore"
            ? [
                _vm.showBankId()
                  ? [
                      !_vm.isOnlyOneAuthenticationMethodAvailable()
                        ? _c("hr-delimiter", { attrs: { text: _vm.$t("or") } })
                        : _vm._e(),
                      _vm._v(" "),
                      _c("bank-id-button", {
                        attrs: {
                          "is-enforced": _vm.isOnlyOneAuthenticationMethodAvailable()
                        }
                      })
                    ]
                  : _vm._e(),
                _vm._v(" "),
                _vm.showMojeId()
                  ? [
                      !_vm.isOnlyOneAuthenticationMethodAvailable()
                        ? _c("hr-delimiter", { attrs: { text: _vm.$t("or") } })
                        : _vm._e(),
                      _vm._v(" "),
                      _c("moje-id-login-button"),
                      _vm._v(" "),
                      !!_vm.mojeIdErrors.length
                        ? _c("error-note", {
                            attrs: { "error-list": _vm.mojeIdErrors }
                          })
                        : _vm._e()
                    ]
                  : _vm._e(),
                _vm._v(" "),
                _vm.site === "admin"
                  ? [
                      _c("hr-delimiter", { attrs: { text: _vm.$t("or") } }),
                      _vm._v(" "),
                      _c("account-switch", { attrs: { account: _vm.account } })
                    ]
                  : _vm._e()
              ]
            : _vm._e()
        ],
        2
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }