<template>
  <b-form
    @submit.stop.prevent="emitSubmit"
    class="content"
    autocomplete="on"
    action="javascript:;"
    method="post"
    novalidate
  >
    <info
      v-if="infoKey"
      class="info theme-bg-light theme-border"
    >
      {{ $t(infoKey) }}
    </info>

    <slot />

    <recaptcha @verified="$emit('errors', [])" />

    <info
      v-if="info2Key"
      v2
      class="info theme-bg-light theme-border"
    >
      <h2>{{ $t(info2Key + ".title") }}</h2>
      <template v-if="info2callback">
        {{ $t(info2Key + ".text1") }}
        <a
          @click="info2callback"
          href="javascript:;"
          class="theme-color"
        >
          {{ $t(info2Key + ".link") }}
        </a>
        {{ $t(info2Key + ".text2") }}
      </template>
      <template v-else>
        {{ $t(info2Key + ".text") }}
      </template>
    </info>

    <error-note
      v-if="!!errors.length"
      :error-list="errors"
    />

    <slot name="lower" />
  </b-form>
</template>

<script>
import { BForm } from "bootstrap-vue";
import { mapState } from "vuex";

import ErrorNote from "../ErrorNote";
import Recaptcha from "../Recaptcha";
import Info from "../Info";

export default {
  name: "LoginLayout",
  components: {
    BForm,
    ErrorNote,
    Recaptcha,
    Info,
  },
  props: {
    errors: {
      type: Array,
      default: () => [],
    },
    info: {
      type: String,
      default: "",
    },
    info2: {
      type: String,
      default: "",
    },
    info2callback: {
      type: [Function, null],
      default: null,
    },
  },
  computed: {
    ...mapState(["account"]),
    infoKey() {
      return this.info || this.$route.query.info;
    },
    info2Key() {
      return this.info2 || this.$route.query.info2;
    },
    usecase() {
      // usecase: restore, newpass, login
      return this.$route.query.usecase || "login";
    },
    btnLabel() {
      return {
        login: "next",
        password: "login.imperative",
        terms: "go_to_app",
        forbidden: "forbidden.button",
      }[this.usecase];
    },
  },
  beforeRouteLeave(to, from, next) {
    this.loading ? next(false) : next();
  },
  methods: {
    emitSubmit() {
      this.$emit("submit");
    },
  },
};
</script>

<style lang="scss">
@import "bootstrap/scss/utilities/flex";
@import "bootstrap/scss/utilities/display";
@import "bootstrap/scss/forms";
@import "bootstrap/scss/custom-forms";
@import "bootstrap/scss/spinners";
</style>

<style lang="scss" scoped>
.info {
  font-weight: 500;
  margin-bottom: 1.5rem;

  ::v-deep ul {
    padding-left: 0.5rem;
  }
}

.login-form {
  margin-bottom: 1rem;
}

.username {
  ::v-deep .form-label-group {
    margin-top: $h2-space-c2;
  }
}

.password {
  ::v-deep .form-label-group {
    margin-top: calc(1rem - (#{$border-width} * 2));
  }
}

.captcha {
  margin-top: 0.5rem;
  font-size: 0.8125rem;
}

.terms-checkbox {
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;
  font-weight: bolder;
  cursor: pointer;
}

::v-deep .terms-checkbox-invalid .custom-control-input ~ .custom-control-label {
  color: $red;

  &:before {
    border-color: $red;
  }
}

.d-xs-none {
  @media (max-width: 320px) {
    display: none;
  }
}

.shrinked {
  display: block;
  overflow: hidden;
  height: 0;
}
</style>