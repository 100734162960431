import Bowser from 'bowser';
import { config } from "./services/config";
import store from "./store/store";

const bowser = Bowser.getParser(window.navigator.userAgent);
const _isOutdatedBrowser = bowser.satisfies({
  ie: '<10',
  safari: '<7',
  chrome: '<40',
  firefox: '<60',
  android: '<4',
  iOS: {
    firefox: '<13',
  },
});
const _isIE = bowser.satisfies({
  ie: '>=1',
});

const provided = Object.create(null);
export function provide(payload) {
  Object.assign(provided, payload);
}

/**
 * Get the URL parameters
 * source: https://css-tricks.com/snippets/javascript/get-url-variables/
 * @param  {String} url The URL
 * @return {Object}     The URL parameters
 */
export function getQueryParams(url) {
  const params = {};
  const parser = document.createElement('a');
  parser.href = url;
  const query = parser.search.substring(1);
  const vars = query.split('&');
  for (let i = 0; i < vars.length; i++) {
    const pair = vars[i].split('=');
    params[pair[0]] = decodeURIComponent(pair[1]);
  }
  return params;
}

/**
 * Utility to map globally registered filters into the methods object of a vue component
 *
 * USAGE:
 * import { mapFilters } from 'path/to/utilities';
 *
 * export default {
 *     methods: {
 *         ....mapFilters(["sentence"])
 *     }
 * }
 */
// source: https://github.com/vuejs/Discussion/issues/405#issuecomment-287371191
export function mapFilters(filters) {
  return filters.reduce((result, filter) => {
    result[filter] = function (...args) {
      return this.$options.filters[filter](...args);
    };
    return result;
  }, {});
}

/**
 * Utility to map objects in array into one mapping object by provided key
 *
 * @param {string} key
 * @param {object[]} arr
 * @return {object} - see example
 *
 * @example
 * import { mapBy } from 'path/to/utilities';
 *
 * mapBy('id',[
 *  {id:'#1', payload:'example'},
 *  {id:'#2', payload:'example2'}
 * ]);
 *
 * returns:
 * {
 *  '#1': {id:'#1', payload:'example'}
 *  '#2': {id:'#2', payload:'example2'}
 * }
 */
export function mapBy(key, arr) {
  return arr.reduce((acc, val) => (acc[val[key]] = val) && acc, {});
}

export function isOutdatedBrowser() {
  return _isOutdatedBrowser;
}

export function isIE() {
  return _isIE;
}

// export function isTouchDevice() {
//   return bowser.tablet || bowser.mobile;
// }

export function satisfies(def) {
  return bowser.satisfies(def);
}

/**
 * Utility to get url from config maps
 *
 * @param {string} key
 * @return {string} - see example
 *
 * @example
 * import { resolveCompanyLink } from 'path/to/utilities';
 *
 * resolveCompanyLink('termsOfServiceUrl');
 *
 * returns: https://www.websupport.cz/obchodni-podminky
 */
export function resolveCompanyLink(key) {
  const urlMap = config.company.links;
  const urlMapKey = provided?.i18nService?.getLang();

  return urlMapKey in urlMap ? urlMap[urlMapKey][key] : urlMap['en'][key];
}

export function isMobileDevice(){
    return /Android|iPhone/i.test(navigator.userAgent);
}

export function catch2fa(error) {
    if (
        // catch "Two-factor authentication required" case
        error?.response?.data?.type !== "/user/2fa"
    ) {
        return false; // not the case
    }

    const twoFactorOptions = error.response.headers["www-authenticate"].split(", ");

    store.commit("updateState", { twoFactorOptions });

    if (twoFactorOptions.includes("webauthn")) {
        store.commit("updateState", { active2fa: "webauthn" });
    } else if (twoFactorOptions.includes("totp")) {
        store.commit("updateState", { active2fa: "totp" });
    } else if (twoFactorOptions.includes("email")) {
        store.commit("updateState", { active2fa: "email" });
    } else if (twoFactorOptions.includes("sms")) {
        store.commit("updateState", { active2fa: "sms" });
    }

    return true;
}