import { cloneDeep } from 'lodash';

const initialState = {
  code: undefined, // (number) - totp, sms code, pass reset code...
  login: undefined, // (string) - selected login
  webauthnLoginEnabled: false,
  forcedAuthenticationMethod: undefined, // (string) - forced authentication method
  pz: undefined, // (string) - selected password
  account: 'standard', // (string) - account/user type standard or email
  activeLogin: undefined, // (string) - currently logged-in user login
  isUserSubAdmin: false,
  loggedUser: {
    // (object) - logged-in user meta data
    // login: String
    // access: Boolean
  },
  email: undefined, // (string) - user input email
  emails: [], // (string[]) - user emails returned by BE
  phone: undefined, // (string) - user input phone
  site: undefined, // (string) - coming from site
  isHelpdesk: false, // (boolean) - coming from query parameter
  redirectTo: undefined, // (string) - redirect destination after successful login
  rememberedUsers: [], // (object[]) array of remembered logins { login: String, webauthnLoginEnabled: Boolean, access: Boolean, date: String }
  company: {
    defaultRedirectUrl: 'https://admin.websupport.sk',
    id: 'websupport.sk',
    knowledge: 'https://www.websupport.sk/podpora',
    privacy: 'https://www.websupport.sk/ochrana-osobnych-udajov',
    terms: 'https://www.websupport.sk/obchodne-podmienky',
  },
  active2fa: undefined, // (string) - currently selected 2fa method
  twoFactorOptions: [], // (string[]) - currently available 2fa methods
  exponea: {
    enabled: false,
    url: 'https://analytics-api.websupport.sk',
    token: '',
  },
};

export default function getInitialState() {
 return cloneDeep(initialState);
}
